import { NavigateOptions } from 'react-router-dom';

import { KnownEmployerProfitStatus } from 'api/StudentLoanApi';

import EmployerType from 'components/StudentLoanForgiveness/Questions/EmployerType';
import NonProfitStartDate from 'components/StudentLoanForgiveness/Questions/NonProfitStartDate';
import StudentLoanSavings from 'components/StudentLoanForgiveness/StudentLoanSavings/StudentLoanSavings';
import BookAdvisor from 'components/StudentLoanForgiveness/WereHereToHelp/BookAdvisor';
import BookAppointment from 'components/StudentLoanForgiveness/BookAppointment/BookAppointment';
import DependentsQuantity from 'components/StudentLoanForgiveness/Questions/DependentsQuantity';
import ScheduleDetails from 'components/StudentLoanForgiveness/ScheduleDetails/ScheduleDetails';
import SaveAwareness from 'components/StudentLoanForgiveness/Questions/SaveAwareness';
import PaymentSituation from 'components/StudentLoanForgiveness/Questions/PaymentSituation';
import ApplyOptions from 'components/StudentLoanForgiveness/Apply/ApplyOptions';
import ApplyStepsIntro from 'components/StudentLoanForgiveness/Apply/ApplyStepsIntro';
import ApplyOnYourOwn from 'components/StudentLoanForgiveness/Apply/ApplyOnYourOwn';
import { ApplyNavigation } from 'components/StudentLoanForgiveness/Apply/applySteps';
import PaymentResult from 'components/StudentLoanForgiveness/Apply/PaymentResult';
import Submit from 'components/StudentLoanForgiveness/Apply/Submit';
import PersonalDetailsPage from 'components/StudentLoanForgiveness/Apply/PersonalDetailsPage';
import ApplyStepsCompleted from 'components/StudentLoanForgiveness/Apply/ApplyStepsCompleted';
import Mohela from 'components/StudentLoanForgiveness/Questions/Mohela';
import ComeBackWhenDue from 'components/StudentLoanForgiveness/ComeBackWhenDue/ComeBackWhenDue';
import CheckForgiveness from 'components/StudentLoanForgiveness/CheckForgiveness/CheckForgiveness';

import { RoutePath } from 'enums/Routes';
import {
  StudentLoanEmployerResult,
  StudentLoanBookAdvisorResult,
  StudentLoanBookAppointmentResult,
  StudentLoanSavingsResult,
  StudentLoanSaveRepaymentPlanResult,
  StudentLoanApplyOptionsResult,
  StudentLoanApplyOnYourOwnResult,
  StudentLoanPayingResult,
  PaymentPageResult,
  MohelaResult,
  ComeBackWhenDueResult,
  PaymentSituationResult,
} from 'enums/StudentLoanForgivenessFlowResults';
import { RootState } from 'handlers';
import { EmploymentDetailsResult, StepsResult } from 'enums/FlowNextResults';
import { StudentLoanDataState } from 'handlers/studentLoanData';

import { RouterType } from './FlowRouter';

export type NextFunction = (state: RootState, navigate: (to: RoutePath, options?: NavigateOptions) => void) => void;

const title = 'Student Loan Forgiveness';

export const getStudentLoanForgivenessRoutes = (next: NextFunction): RouterType => ({
  [RoutePath.StudentLoanEmployerType]: {
    navigationInfo: { showBackLink: false, title },
    component: EmployerType,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanEmployerResult.NonProfit:
          navigate(RoutePath.StudentLoanNonProfitStartDate);
          break;
        case StudentLoanEmployerResult.ForProfit:
          navigate(RoutePath.StudentLoanSaveRepaymentPlan);
          break;
        case StudentLoanEmployerResult.NotSure:
          if (state.studentLoanData.allLoansMohela) {
            navigate(RoutePath.StudentLoanMohela);
          } else {
            navigate(RoutePath.StudentLoanDependentsQuantity);
          }
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanNonProfitStartDate]: {
    navigationInfo: { showBackLink: true, title },
    component: NonProfitStartDate,
    handleNext: ({ navigate, state }) => () => {
      if (state.studentLoanData.allLoansMohela) {
        navigate(RoutePath.StudentLoanMohela);
      } else {
        navigate(RoutePath.StudentLoanDependentsQuantity);
      }
    },
  },
  [RoutePath.StudentLoanMohela]: {
    navigationInfo: { showBackLink: true, title },
    component: Mohela,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case MohelaResult.EnrolledWantForgiveness:
          navigate(RoutePath.StudentLoanCheckForgiveness);
          break;
        default:
          navigate(RoutePath.StudentLoanDependentsQuantity);
      }
    },
  },
  [RoutePath.StudentLoanDependentsQuantity]: {
    navigationInfo: { showBackLink: true, title },
    component: DependentsQuantity,
    handleNext: ({ navigate, state }) => () => {
      if (!state.studentLoanData.hasMonthlyPayments) {
        navigate(RoutePath.StudentLoanPaymentSituations);
      } else {
        navigate(RoutePath.StudentLoanSavings);
      }
    },
  },
  [RoutePath.StudentLoanCheckForgiveness]: {
    navigationInfo: { showBackLink: true, title },
    component: CheckForgiveness,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanBookAdvisor);
    },
  },
  [RoutePath.StudentLoanPaymentSituations]: {
    navigationInfo: { showBackLink: true, title },
    component: PaymentSituation,
    handleNext: ({ navigate }) => (result) => {
      switch (result as PaymentSituationResult) {
        case PaymentSituationResult.ImAStudent:
          navigate(RoutePath.StudentLoanComeBackWhenDue);
          break;
        default:
          navigate(RoutePath.StudentLoanSavings);
      }
    },
  },
  [RoutePath.StudentLoanComeBackWhenDue]: {
    navigationInfo: { showBackLink: true, title },
    component: ComeBackWhenDue,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case ComeBackWhenDueResult.TalkToAnAdvisor:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        default:
          next(state, navigate);
      }
    },
  },
  [RoutePath.StudentLoanSaveRepaymentPlan]: {
    navigationInfo: { showBackLink: true, title },
    component: SaveAwareness,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanSaveRepaymentPlanResult.Enrolled:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        case StudentLoanSaveRepaymentPlanResult.NotEnrolled:
        case StudentLoanSaveRepaymentPlanResult.HaveNotHeard:
          navigate(RoutePath.StudentLoanDependentsQuantity);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanSavings]: {
    navigationInfo: { showBackLink: true, title },
    component: StudentLoanSavings,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanSavingsResult.Next:
          navigate(RoutePath.StudentLoanApplyOptions);
          break;
        case StudentLoanSavingsResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanBookAppointment]: {
    navigationInfo: { showBackLink: true, title },
    component: BookAppointment,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanBookAppointmentResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanBookAppointmentResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanBookAdvisor]: {
    navigationInfo: { showBackLink: true, title },
    component: BookAdvisor,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanBookAdvisorResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanBookAdvisorResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanScheduleDetails]: {
    navigationInfo: { showBackLink: false, title },
    component: ScheduleDetails,
    handleNext: ({ navigate, state }) => () => {
      navigate(RoutePath.StudentLoanScheduleDetails);
      next(state, navigate);
    },
  },
  [RoutePath.StudentLoanApplyOptions]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyOptions,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanApplyOptionsResult.ApplyWithUs:
          navigate(RoutePath.StudentLoanApplyWithUs);
          break;
        case StudentLoanApplyOptionsResult.ApplyOnYourOwn:
          navigate(RoutePath.StudentLoanApplyOnYourOwn);
          break;
        case StudentLoanApplyOptionsResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanApplyOptionsResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyOnYourOwn]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyOnYourOwn,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanApplyOnYourOwnResult.ApplyWithUs:
          navigate(RoutePath.StudentLoanApplyWithUs);
          break;
        case StudentLoanApplyOnYourOwnResult.Done:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyWithUs]: {
    navigationInfo: { showBackLink: true, title },
    component: PersonalDetailsPage,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanApplySubmit);
    },
  },
  [RoutePath.StudentLoanApplySubmit]: {
    navigationInfo: { showBackLink: true, title },
    component: Submit,
    handleNext: ({ state, navigate }) => (result) => {
      if (result === PaymentPageResult.PayrollDeduction) {
        navigate(RoutePath.StudentLoanApplyStepsIntro);
      } else if (result === PaymentPageResult.NotNow) {
        next(state, navigate);
      } else {
        navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.StudentLoanApplyPaymentResult]: {
    navigationInfo: { showBackLink: true, title },
    component: PaymentResult,
    handleNext: ({ navigate }) => (result) => {
      if (result === StudentLoanPayingResult.Success) {
        navigate(RoutePath.StudentLoanApplyStepsIntro);
      } else {
        navigate(RoutePath.StudentLoanApplySubmit);
      }
    },
  },
  [RoutePath.StudentLoanApplyStepsIntro]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyStepsIntro,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanApplySteps);
    },
  },

  [RoutePath.StudentLoanApplySteps]: {
    component: ApplyNavigation,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case StepsResult.Completed:
          navigate(RoutePath.StudentLoanApplyStepsCompleted);
          break;
        case EmploymentDetailsResult.Continue:
          break;
        case EmploymentDetailsResult.Rejected:
        case StepsResult.Rejected:
          navigate(RoutePath.OfferStatus);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyStepsCompleted]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyStepsCompleted,
    handleNext: ({ state, navigate }) => () => {
      next(state, navigate);
    },
  },
});

export const getStudentLoanStart = (studentLoanData: StudentLoanDataState) => {
  switch (studentLoanData.knownEmployerProfitStatus) {
    case KnownEmployerProfitStatus.NonProfit:
      return RoutePath.StudentLoanNonProfitStartDate;
    case KnownEmployerProfitStatus.ForProfit:
      return RoutePath.StudentLoanSaveRepaymentPlan;
    default:
      return RoutePath.StudentLoanEmployerType;
  }
};
